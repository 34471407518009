/* Responsive CSS for Infrastructure component */

/* For small devices (e.g., phones) */
@media (max-width: 576px) {
    .row {
      flex-wrap: wrap; /* Allow columns to wrap onto new lines */
    }
    .col {
      flex: 0 0 100%; /* Make columns take up full width */
    }
  }
  
  /* For medium devices (e.g., tablets) */
  @media (min-width: 768px) {
    .col {
      flex: 0 0 50%; /* Make columns take up half width */
    }
  }
  
  /* For large devices (e.g., desktops) */
  @media (min-width: 992px) {
    .col {
      flex: 0 0 33.33%; /* Make columns take up one-third width */
    }
  }
  .container{
    padding: 20px; /* Example: Add padding */
    margin-bottom: 20px; /* Example: Add margin */
    background-color: #f0f0f0; /* Example: Add background color */
    justify-content: flex-start;
  }
  
  .title{
    background-color: #957eaaca;
    color: white;
    margin-bottom: 30px;
    text-align: center;
    padding: 10px;
  }
  .heading{
    margin-bottom: 10px;
  }
  .imgFluid{
    width: 90%;
    height: auto;
    display: inline-block;
    margin: 15px;
  }
  .secondHeading{
    text-align: center;
    background-color: rgba(224, 229, 230, 0.751);
  }