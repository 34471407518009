.bannerImage{
    width: 100%;
    height: 600px;
    object-fit: cover;
}
.pmCards{
    text-align: center;
    margin-top: -100px;
}
pre{
    overflow: hidden;
    width: 1090px;
    color: rgb(19, 12, 12);      
}