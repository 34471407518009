.title{
    background-color: #957eaaca;
    color: white;
    margin-bottom: 30px;
    text-align: center;
    padding: 10px;
    width: 100%;
}
.imgFluid{
    width: 75%;
    height: auto;
    margin-bottom: 20px;
    display: inline-block; /* Make the image a block element */ 
    margin-left: auto;
   margin-right: auto;
  }
  .secondHeading{
    text-align: center;
    background-color: rgba(224, 229, 230, 0.751);
    margin-top: 20px;
  }
  .container{
    max-width: 100%;
    text-align: center;
  }
  .para{
    padding: 20px;
  }