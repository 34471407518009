/* App.css */
.App {
  text-align: center;
}

header {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  height: 100px;
}

header h1 {
  margin-right: 5%;
}

section {
  padding: 50px 0;
}

section h2 {
  margin-bottom: 20px;
}

footer {
  background-color: #4d0ca8b5;
  color: #fff;
  padding: 20px 0;
  width: 100%;
  text-align: center;
}
