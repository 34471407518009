.nsqf-info {
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .nsqf-info h2 {
  background-color: #957eaaca;
  color: white;
  margin-bottom: 30px;
  text-align: center;
  padding: 10px;
  }
  
  .nsqf-info p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .linkAnchor{
    text-align: center;
    padding: 10px;
  }