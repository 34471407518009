.title{
    background-color: #957eaaca;
    color: white;
    margin-bottom: 30px;
    text-align: center;
    padding: 10px;
}
.para{
    padding: 5px;
    margin-left: 200px;
   
}
.secondHeading{
    text-align: center;
    background-color: rgba(224, 229, 230, 0.751);
  }
  .subheading{
    padding: 2px;
    font-weight: 800;
    margin-top: 10px;
    margin-left: 200px;
  }
  .container{
    padding: 20px;
  }
  .paragraph{
    padding: 10px;
  }
  .display{
    display: inline-block;
  }