
.aboutPmShreeContent {
  margin-bottom: 40px;
}
.principalMessage {
  width:35%;
  height:30%;
 display: inline-block;

}
.anchor{
  text-align: center;
}
/* You can customize these styles further as per your requirements */

.card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 27rem;
  object-fit: cover;
  border: 2px solid grey;
  margin-bottom: 20px;
}

.cardTitle {
  background-color: #957eaaca;
    color: white;
    margin-bottom: 30px;
    text-align: center;
    padding: 10px;
}

.card-text {
  max-width: 1000px;
  color: rgb(63, 17, 17);
  margin-left: 10px;
  margin-top: 30px;
  margin-bottom: 10px;
}
/* If you want to adjust spacing between elements */
.row {
  margin-bottom: 15px;
}


/* For making the image responsive */
.img-fluid {
  max-width: 100%;
  height: 40rem;
  object-fit: contain;
  align-items: center;
}
@media (max-width: 800px){
  .img-fluid  {
    width: 300px;
    height: 50rem;
    margin: 5px;
    display: inline-block;
  }
}
