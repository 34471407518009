.transparentBoxContainer {
  margin-bottom: 50px;
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.backgroundImage {
  background-image: url("/public/StudentGathering.jpg");
  background-size: cover;
  width: 100%;
  height: auto;
}

.content {
  padding: 20px;
  text-align: center;
  color: white;
  max-width: 100%;
  background-color: #00000066;
}

.paragraph {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: large;
  color: whitesmoke;
}
