.CardColFirst{
    margin: 20px;
    width: 20rem;
    display: inline-block;
}

.CardColSecond{
    margin: 20px;
    width: 20rem;
    display: inline-block;
    vertical-align: top;
}
.cardList{
    text-align: center;
}

.cardHeader{
    text-align: center;
}
.CardColThird{
    margin: 20px;
    width: 20rem;
    display: inline-block;
    vertical-align: top;
}

.CardCol {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    justify-content: center;
}

