/* Footer container */
.footer {
    background-color: #684883;
    color: #fff;
    padding: 50px 0;
  }
  
  /* Footer content */
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  /* Footer column */
  .footer-column {
    width: 30%;
    padding: 0 20px;
  }
  
  /* Footer column heading */
  .footer-column h3 {
    font-size: 1.2rem;
  }
  
  /* Social icons */
  .social-icons {
    display: flex;
  }
  
  .social-icons a {
    color: #fff;
    margin-right: 10px;
  }
  
  /* Footer bottom */
  .footer-bottom {
    background-color: #0f0f105f;
    padding: 10px 0;
    text-align: center;
  }
  