/* Custom CSS for the photo grid */

/* Custom CSS for the photo grid */

.container {
  margin-top: 20px; /* Adjust margin as needed */
}

.row {
  display: flex;
  flex-wrap: wrap;
  max-width: 1600px;
  justify-content: center;
  margin-right: auto; /* Adjust margin as needed */
  margin-left: auto; /* Adjust margin as needed */
  margin-bottom: 50px;
}

.col-md-3 {
  flex: 0 0 50%; /* Adjust width of columns (2 columns in total) */
  max-width: 50%; /* Adjust width of columns (2 columns in total) */
  padding-right: 5px; /* Adjust padding as needed */
  padding-left: 5px; /* Adjust padding as needed */
}

.imgFluid {
  width: 500px;
  object-fit: cover;
  height: 30rem; /* Adjust height of images */
  display: inline-block;
  border: 2px solid rgba(194, 173, 194, 0.84);
  margin: 10px; /* Adjust margin as needed */
}

@media (max-width: 800px) {
  .imgFluid {
    width: 300px;
    height: 20rem;
  }
}

.title {
  background-color: #957eaaca;
  color: white;
  margin-bottom: 30px;
  text-align: center;
  padding: 10px;
}
