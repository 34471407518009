.customRow{
        background-color: #ffffff; 
        padding: 20px;
        border: 1px solid #d3d2d2ea; 
        border-radius: 5px;
        margin: auto;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        display: flex; /* Use flexbox layout */
        text-align: center;
        align-self: center;
        justify-content: center;
        width: 90%;
        margin-bottom: 40px;
      
}
  
  .image {
    width: 100%; /* Ensures the image fills the entire card width */
    height: 370px; /* Adjust the image height as needed */
    object-fit: cover; /* Ensures the image covers the entire space */
  }
  
  .cards{
    margin: 10px;
  }
  .heading{
    color: brown;
  }