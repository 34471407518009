.contact-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .contact-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .contact-info {
    flex: 1;
  }
  .title{
  background-color: #957eaaca;
  color: white;
  margin-bottom: 30px;
  text-align: center;
  padding: 10px;
  }
  .contact-info h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .map-responsive {
    overflow: hidden;
    padding-bottom: 56.25%; /* Aspect ratio 16:9 */
    position: relative;
  }
  
  .map-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  