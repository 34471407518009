

.schoolName {
  display: inline-block;
  margin-left: 20px;
}

.schoolName h3 {
  text-align: right;
}

.pmNavbarToggle  {
  margin-left: auto;
}
.dropdown{
  margin-right:40px ;
}

.pmTitleContainer{
  margin-right: 40px;
}


@media (max-width: 800px) {
  /* Styles to apply when the viewport width is less than 800px */

  .pmTitleContainer {
    text-align: center;
  }

  .schoolName {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .schoolName h3 {
    text-align: center;
  }
}

.nav-link:hover {
  background-color: #b9bb76;
}


.dropdown:hover .dropdown-toggle {
  color: #fff; /* Change text color of dropdown toggle on hover */
  background-color: #6c757d; /* Change background color of dropdown toggle on hover */
}

.dropdown:active .dropdown-toggle {
  color: #fff; /* Change text color of dropdown toggle when active */
  background-color: #495057; /* Change background color of dropdown toggle when active */
}

.dropdown-menu:hover .dropdown-item {
  background-color: #6c757d; /* Change background color of dropdown item on hover */
}

.dropdown-menu:active .dropdown-item {
  background-color: #495057; /* Change background color of dropdown item when active */
}

