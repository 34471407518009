.infoCard {
  display: inline-block;
  margin: 30px;
  width: 20rem;
  height: 25rem;
  align-items: center;
  vertical-align: top;
  text-align: center;
}

.icons {
  text-align: center;
  color: rgb(77, 166, 184);
  margin: 10px;
}
.heading{
  color: brown;
}